/* Tooltip container */
.tooltip-container {
  position: relative;
  cursor: pointer;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  max-width: 180px; /* Set the maximum width */
  min-width: 150px;
  background-color: #e6f0ff;
  color: #000;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */
  font-size: 12px;
  font-weight: 500;
  white-space: normal; /* Ensure text wraps */
  word-wrap: break-word; /* Ensure long words break */
  word-break: break-word; /* Ensure long words break */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
}

/* Tooltip arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #e6f0ff transparent transparent transparent;
}

/* Show the tooltip text when hovering */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
}
