/* src/app/globals.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Cairo", sans-serif;
  /* --font-sarabun: "Sarabun", sans-serif; */
  
}
/* 
body {
  @apply font-sarabun;
} */
/* * {
  user-select: none;
} */
html,
body {
  font-family: var(--font-sarabun);
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE 10+ */
}

.Dropdown-control {
  border-radius: 8px !important;
  border-color: #101010 !important;
}

.css-t3ipsp-control {
  border-color: #00a843 !important;
  box-shadow: 0 0 0 1px #00a843 !important;
}
.css-15lsz6c-indicatorContainer {
  color: #00a843 !important;
}

/* width */
::-webkit-scrollbar {
  height: 6px;
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9ca19f;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #389365;
}
