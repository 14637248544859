.main-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:20px;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
}
.sideComponentCardWrapper {
  min-width: max-content;
  min-height: min-content;
  border-radius: 14px;
  border: 1px solid #d7e1e9;
  opacity: 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.leaveComponentCardWrapper {
  min-width: max-content;
  min-height: min-content;
  border-radius: 14px;
  border: 1px solid #d7e1e9;
  opacity: 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.dashboardSideComponent{
  display: flex;
    flex-direction: column;
    gap: 15px;
}
.main-title{
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1f1f1f;
}
.title {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1f1f1f;
}
.department {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #454545;
}
.date-time-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.date-wrapper,
.time-wrapper,
.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
}
@media screen and (max-width: 768px) { 
  .sideComponentCardWrapper, 
  .leaveComponentCardWrapper {
    min-width: 90%; 
    width:   70%; 
    max-width: 90vw; 
    margin-left: 5%; 
    margin-right: 5%; 
  }
  .date {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #545454;
  }
}
