thead:is([role="presentation"]) tr{
    display: flex;
        flex-direction: row;
        gap: 10px;
}
thead:is([role="presentation"]) tr th{
    border:none !important;
    width: 100%;

}
thead:is([role="presentation"]) tr th div{
    background-color: #E6F4FF;
    border: 1px solid #BBDEF6;
    border-radius: 14px;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* typography */
    font-size: 17px;
    font-weight: 500;
    line-height: 25.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
tbody:is([role="presentation"]) tr {
    display: flex;
        flex-direction: row;
        gap: 10px;
}
tbody:is([role="presentation"]) tr td{
    width: 100%;
    background-color: #ffff;
    border: 1px solid #CFECE4;
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}
tbody:is([role="presentation"]) tr div{
    display: flex;
        flex-direction: column;
        align-items: flex-start;
}
table:is([role="presentation"]){
    margin-top: 10px;

}
tbody:is([role="presentation"]){
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
/* event styling */
.fc-event-title{
    border: none;
    text-wrap: auto;
    width: 100%;
    /* typography */
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 25.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 5px;
}
/* two events spacing  */
.fc-daygrid-event-harness{
margin-bottom: 15px;
}

/* border top of whole table  */
.fc-theme-standard .fc-scrollgrid{
    border:none !important;
}
/* border bottom of whole table  */
.fc-theme-standard td:is([role="presentation"]){
    border:none !important;

}
/* removing start date and dot  */
.fc-daygrid-event-dot, .fc-event-time{
    display: none !important;
}

/* removing toolbar header  */
/* .fc-header-toolbar:is(.fc-toolbar) {
    display: none;
} */
 /* handling z-index of calendar  */
 .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard{
    z-index: 0;
 }

 /* specifying height of calendar to be half of the screen height  */
 .fc-view-harness{
    height: 60vh !important ;
 }

 