:root {
  --green: #00a843;
  --gray: #bbbbbb;
}

.btn {
  outline: none;
  height: 100%;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  background: #00a843;
  border: 2px solid var(--green);
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 0;
  cursor: pointer;
  transition: all 0.25s ease;
}

.btnSec {
  border: 1px solid #00a843;
  background: transparent;
  color: #00a843;
}

.btn:hover {
  background: #389365;
}

.btnSec:hover {
  background: #fff;
}

.btn:active {
  letter-spacing: 2px;
}

.onclic {
  width: 40px;
  border-color: var(--gray);
  border-width: 3px;
  font-size: 0;
  border-left-color: var(--green);
  animation: rotating 2s 0.25s linear infinite;
  border-radius: 100%;
  background: #fff;
}

.onclic::after {
  content: "";
}

.onclic:hover {
  color: var(--green);
  background: white;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
